import { useState, useEffect } from "react";
import { storeUser, removeUser, retrieveUser } from "../utils/userStore";
// import { profile } from "../services/userRequest";

export default function useUser() {
  const [accessUser, setAccessUser] = useState(null);

  useEffect(() => {
    const user = retrieveUser();
    setAccessUser(user);
  }, []);

  const setUser = (user) => {
    if (user) {
      storeUser(user);
    }
  };

  const clearUserData = () => {
    removeUser();
  };

  // const { refetch } = useQuery([reactQueryKeys.profile], () => profile({ method: "GET" }), {
  //   enabled: false,
  //   onSuccess: (data) => {
  //     // @ts-ignore
  //     setUser(data?.data?.data);
  //   },
  // });

  return {
    user: retrieveUser(),
    setUser,
    clearUserData,
    // revalidateUser: refetch,
    accessUser,
  };
}
