import React, { useEffect, useState, useTransition } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import AuthService from '../../../services/api/auth.service';
import { toastError, toastSuccess } from '../../common/toast/toastHelper';
import { Form, Input, Spin, Checkbox, Button } from 'antd';
import useToken from '../../../lib/hooks/useToken';
import useUser from '../../../lib/hooks/useUser';
import { useTranslation } from 'react-i18next';
import { FcGoogle } from 'react-icons/fc';
import AuthSectionWrapper from './AuthSectionWrapper';
import SubmitButton from '@/components/common/reusable/SubmitButton';
import { signIn, useSession } from 'next-auth/react';

const LoginContentArea = () => {
	const { data: session, status: authStatus } = useSession();
	const [sociallLoading, setSocialLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const { setToken } = useToken();
	const { setUser, user } = useUser();
	const { t } = useTranslation();

	// console.log("==>session", session);
	useEffect(() => {
		if (authStatus == 'authenticated') {
			setToken(session.user.token);
			setUser(JSON.stringify(session.user));

			router.push('/dashboard');
		}
	}, [authStatus]);

	const [form] = Form.useForm();

	const router = useRouter();

	const handleSubmit = async (values) => {
		setLoading(true);

		const { ...allValues } = values;

		let formData = new FormData();

		for (const [key, value] of Object.entries(allValues)) {
			if (value) {
				formData.append(key, value);
			}
		}

		await signIn('credentials', {
			...values,
			redirect: false,
		})
			.then((data) => {
				console.log(data);
				if (data.ok) {
					toastSuccess('Login Success');
				} else {
					toastError('Login Unsuccessful');
				}

				return true;
			})
			.catch((error) => {
				let errMsg = error?.response?.data?.message || 'Something went wrong';

				console.log(errMsg);
				toastError(errMsg[0]);

				return false;
			})
			.finally(() => {
				setLoading(false);
			});
		// AuthService.signIn(formData)
		// 	.then(
		// 		(data) => {
		// 			if (data) {
		// 				setToken(data.token);
		// 				setUser(JSON.stringify(data.user));
		// 				setLoading(true);
		// 				toastSuccess('login Success');
		// 				router.push('/dashboard');
		// 			}

		// 			return true;
		// 		},
		// 		(error) => {
		// 			let errMsg = error?.response?.data?.message || 'Something went wrong';

		// 			console.log(errMsg);
		// 			toastError(errMsg[0]);

		// 			return false;
		// 		}
		// 	)
		// 	.finally(() => {
		// 		setLoading(false);
		// 	});
	};

	const googleSignIn = () => {
		setSocialLoading(true);
		signIn('google');
	};

	return (
		<>
			<AuthSectionWrapper>
				<div className="auth_from_content">
					<div className="auth_from_title">
						<h2>Sign In</h2>
					</div>

					<div className="from_social_login_area">
						<Button
							className="from_social_login_button"
							disabled={authStatus == 'loading' || authStatus == 'authenticated'}
							loading={sociallLoading}
							onClick={(e) => googleSignIn()}
						>
							<FcGoogle />
							<span>Sign in with Google</span>
						</Button>
						<div className="after_devider">
							<span className="after_devider_text">or Sign in with Email</span>
						</div>
					</div>

					<Spin spinning={loading}>
						<Form
							form={form}
							layout="vertical"
							onFinish={handleSubmit}
							disabled={sociallLoading || authStatus == 'loading' || authStatus == 'authenticated'}
							initialValues={{
								remember: true,
							}}
						>
							<Form.Item
								name="email"
								label="Email"
								rules={[
									{
										required: true,
										type: 'email',
										message: 'Please input your valid email!',
									},
								]}
							>
								<Input placeholder="example@gmail.com" prefix={<MailOutlined className="site-form-item-icon" />} />
							</Form.Item>

							<Form.Item
								name="password"
								label="Password"
								rules={[
									{
										required: true,
										message: 'Please input your password!',
									},
								]}
							>
								<Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="password12345" />
							</Form.Item>

							<div className="flex_between my-3">
								<Form.Item
									name="remember"
									noStyle
									rules={[
										{
											required: false,
										},
									]}
								>
									<Checkbox>Remember me</Checkbox>
								</Form.Item>

								<Link className="login-form-forgot" href="/password-reset">
									Forgot password
								</Link>
							</div>

							<SubmitButton title="Login" form={form} loading={loading} />
						</Form>
					</Spin>

					<div className="back_register">
						<p>Not Registerd Yet ?</p>
						<Link href="/registration">Create An Account</Link>
					</div>
				</div>
			</AuthSectionWrapper>
		</>
	);
};

export default LoginContentArea;
