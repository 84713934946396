import { toast } from 'react-toastify';

function toastSuccess(message = '', toastId='success') {
  if (toast.isActive(toastId)){
    toast.update(toastId, { render: message });

  }else{
    toast.success(message, { toastId: toastId });
  }
}

function toastError(message = '', toastId = 'error') {
  if (toast.isActive(toastId)) {
    toast.error(toastId, { render: message });
  } else {
    toast.error(message, { toastId: toastId });
  }
}

const onUploadProgress = (p, toastId) => {
  const progress = p.loaded / p.total;

  // check if we already displayed a toast
  if (toastId.current === null) {
    toastId.current = toast('Upload in Progress', { progress });
  } else {
    toast.update(toastId.current, { progress });
  }
};

export { toastSuccess, toastError, onUploadProgress };