import { useRouter } from 'next/router';
import React from 'react';
import useToken from '../../../lib/hooks/useToken';

const LoginLayout = ({ children }) => {
  const { isLoggedIn } = useToken();
  const router = useRouter();

  if (isLoggedIn) {
    // router.push('/dashboard');
  }

  return (
    <>
      <div>{children}</div>
    </>
  );
};

export default LoginLayout;
