import React from 'react';
import Link from 'next/link';
import Image from "next/legacy/image";
import { siteLogo } from '@/config/config';

const AuthSectionWrapper = ({ children }) => {
	return (
    <div className="auth_page_wrapper">
      <div className="auth_form_content_wrapper">
        <div className="auth_header_image_wrapper">
          <Link href="/" passHref legacyBehavior>
            <Image
              src={siteLogo}
              alt="Picturer"
              className="image_item"
              layout="fill"
              sizes="100vw"
              style={{
                objectFit: "contain",
                objectPosition: "left",
              }}
            />
          </Link>
        </div>

        <div className="auth_form_wrapper">{children}</div>
      </div>

      {/* <div className="auth_banner_content_wrapper">
        <div className="auth_banner_header_section_wrapper">
          <h5>
            Invoicing and Accounting Tools <br />
            Built for Any Sized Business
          </h5>

          <p>
            Welcome to our user-friendly Invoicing Solutions, designed to cater
            to businesses of all sizes. Simplify your financial processes and
            embrace streamlined invoicing with ease. Our intuitive platform
            offers a suite of easy-to-use accounting and
          </p>
        </div>

        <div className="auth_banner_image_section_wrapper">
          <Image
            src="/images/login-image.png"
            alt="Picturer"
            layout="fill"
            objectFit="contain"
          />
        </div>

        <div className="auth_banner_footer_section_wrapper">
          <h5>Effortless Financial Management for Every Business</h5>

          <p>
            Unlock Success: Your Path to Financial Excellence, Any Business
            Size. <br />
            Thrive with our Invoicing and Accounting Tools now!
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default AuthSectionWrapper;
