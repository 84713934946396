import { apiPrefix, apiUrl } from '../../config/config';
import { api } from '../interceptor/auth.interceptor';
import { apiEndpoint } from '../apiEndpoint';

class AuthService {
	static async otpSend(formData) {
		return await api.post(apiUrl + apiEndpoint.otpSendUrl, formData).then((response) => {
			console.log(response);
			return response?.data;
		});
	}

	static async otpVerify(formData) {
		return await api.post(apiUrl + apiEndpoint.otpVerifyUrl, formData).then((response) => {
			console.log(response);
			return response?.data;
		});
	}
	static async register(formData) {
		return await api.post(apiUrl + apiEndpoint.signupUrl, formData).then((response) => {
			console.log(response);
			return response?.data;
		});
	}

	static async signIn(formData) {
		return await api.post(apiUrl + apiEndpoint.loginUrl, formData).then((response) => {
			// console.log(response);
			return response?.data;
		});
	}

	// reset password services

	static async passOtpSend(formData) {
		return await api.post(apiUrl + apiEndpoint.resetPassword.sendOtp, formData).then((response) => {
			console.log(response);
			return response?.data;
		});
	}

	static async passOtpVerify(formData) {
		return await api.post(apiUrl + apiEndpoint.resetPassword.verifyOtp, formData).then((response) => {
			console.log(response);
			return response?.data;
		});
	}

	static async resetPassword(formData) {
		return await api.post(apiUrl + apiEndpoint.resetPassword.passwordReset, formData).then((response) => {
			console.log(response);
			return response?.data;
		});
	}
}

export default AuthService;
